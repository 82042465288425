import { weddingWebsiteCustomValidation } from "../config/constants";
import { FeatureFlags } from '../models/weddingWebsiteTemplates/weddingWebsiteTemplates';
import { exctractContentFromHtml } from "./common";
export const ValidationPageTypes = {
    Title: "Title",
    Story: "Story",
    Gif: "GIf",
    PhotoTimeline: "PhotoTimeline",
    Photo: "Photo",
    Hotel: "Hotel",
    QnA: "QnA",
    Person: "Person",
    Transport: "Transport",
    Activity: "Activity",
    LiveStream: "LiveStream",
    TextBlock: "TextBlock",
    BasicInfo: "BasicInfo",
    HomeCoverText: "HomeCoverText",
    EventInformation: "EventInformation",
    Privacy: "Privacy",
    WebsiteUrl: "WebsiteUrl",
    Registry:"Registry"

}

export function validateField(fieldName, value, pageType) {
    value = typeof value === 'string' ? exctractContentFromHtml(value): value;

    switch (pageType) {
        case ValidationPageTypes.Title:
            return validateTitlePage(fieldName, value);
        case ValidationPageTypes.Story:
            return validateStoryPage(fieldName, value);
        case ValidationPageTypes.Gif:
            return validateGifPage(fieldName, value);
        case ValidationPageTypes.PhotoTimeline:
            return validatePhotoMemoryPage(fieldName, value);
        case ValidationPageTypes.Photo:
            return validatePhotoPage(fieldName, value);
        case ValidationPageTypes.Hotel:
            return validateHotelPage(fieldName, value);
        case ValidationPageTypes.QnA:
            return validateQnAPage(fieldName, value);
        case ValidationPageTypes.Person:
            return validatePersonPage(fieldName, value);
        case ValidationPageTypes.Transport:
            return validateTransportPage(fieldName, value);
        case ValidationPageTypes.Activity:
            return validateActivityPage(fieldName, value);
        case ValidationPageTypes.LiveStream:
            return validateLivestreamPage(fieldName, value);
        case ValidationPageTypes.TextBlock:
            return validateTextBlockPage(fieldName, value);
        case ValidationPageTypes.BasicInfo:
            return validateBasicInfoPage(fieldName, value);
        case ValidationPageTypes.HomeCoverText:
            return validateHomeCoverTextPage(fieldName, value);
        case ValidationPageTypes.EventInformation:
            return validateEventInformationPage(fieldName, value);
        case ValidationPageTypes.Privacy:
            return validatePrivacyPage(fieldName, value);
        case ValidationPageTypes.WebsiteUrl:
            return validateWebsiteUrlPage(fieldName, value);
        case ValidationPageTypes.Registry:
            return validateRegistryPage(fieldName, value);
    }
}

export function validateBase64Image(value) {
  if (typeof value === "string" && value.startsWith("data:image/")) {
    const matches = /^data:(image\/[^;]+);base64,(.+)$/.exec(value);
    if (!matches) {
      return weddingWebsiteCustomValidation.imageValidationMessages
        .invalidFormat;
    }

    const mimeType = matches[1];
    const base64Data = matches[2];
    let paddingLength;
    if (base64Data.endsWith("==")) {
      paddingLength = 2;
    } else if (base64Data.endsWith("=")) {
      paddingLength = 1;
    } else {
      paddingLength = 0;
    }
    const sizeInBytes = (base64Data.length * 3) / 4 - paddingLength;

    if (!mimeType.startsWith("image/")) {
      return weddingWebsiteCustomValidation.imageValidationMessages
        .invalidFormat;
    }

    if (
      sizeInBytes <
      weddingWebsiteCustomValidation.imageMinSize * 1024 * 1024
    ) {
      return weddingWebsiteCustomValidation.imageValidationMessages
        .lessThanMinSize;
    }
    if (
      sizeInBytes >
      weddingWebsiteCustomValidation.imageMaxSize * 1024 * 1024
    ) {
      return weddingWebsiteCustomValidation.imageValidationMessages
        .greaterThanMaxSize;
    }

    return "";
  }

  return "";
}

function validateNotEmpty(value, fieldName, combinedFields = {}) {

    const message = `${fieldName} ${weddingWebsiteCustomValidation.textValueNotExist}`;
    if (Object.keys(combinedFields).length > 0) {
        const isEmpty = Object.values(combinedFields).some(fieldValue =>
            !fieldValue || (typeof fieldValue === 'string' && fieldValue.trim() === '')
        );
        if (isEmpty) {
            return message;
        }
    } else if (!value) {
        return message;
    }

    else if (
        fieldName !== "Date" &&
        typeof value === "string" &&
        value.trim() === ""
    ) {
        return message;
    }

    return "";
}

function validateTitlePage(fieldName, value) {
    if (fieldName === "titleText") {
        return validateNotEmpty(value, "Title text");
    }
    return "";
}

function validateStoryPage(fieldName, value) {
    switch (fieldName) {
        case "title": {
            const emptyTitleError = validateNotEmpty(value, "Title text");
            if (emptyTitleError) return emptyTitleError;
            if (value) return value.length > 120 ? `Title ${weddingWebsiteCustomValidation.charactorLength}` : "";
        }
        case "description": {
            return validateNotEmpty(value, "Description text");
        }
        default: {
            return "";
        }
    }
}

function validateGifPage(fieldName, value) {
    if (fieldName === "gif") {
        if (!value || (Array.isArray(value) && value.length === 0)) {
            return weddingWebsiteCustomValidation.gifValidationMessages.gifNotExist;
        }

        if (typeof value === "string") {
            return "";
        }
        if (value.type !== "image/gif") {
            return weddingWebsiteCustomValidation.gifValidationMessages.invalidFormat;
        }
        if (value.size > weddingWebsiteCustomValidation.gifMaxSize * 1024 * 1024) {
            return weddingWebsiteCustomValidation.gifValidationMessages.greaterThanMaxSize;
        }
        return "";
    }
    return "";
}

function validateImage(value) {
    if (!value || (Array.isArray(value) && value.length === 0)) {
        return weddingWebsiteCustomValidation.imageValidationMessages.imageNotExist;
    }
    if (typeof value === "string") {
        return "";
    }
    if (!value?.type?.startsWith?.("image/") || value.type === "image/gif") {
        return weddingWebsiteCustomValidation.imageValidationMessages.invalidFormat;
    }
    if (value.size < weddingWebsiteCustomValidation.imageMinSize * 1024 * 1024) {
        return weddingWebsiteCustomValidation.imageValidationMessages.lessThanMinSize;
    }
    if (value.size > weddingWebsiteCustomValidation.imageMaxSize * 1024 * 1024) {
        return weddingWebsiteCustomValidation.imageValidationMessages.greaterThanMaxSize;
    }
    return "";
}

function validatePhotoMemoryPage(fieldName, value) {
    switch (fieldName) {
        case "date":
            return validateNotEmpty(value, "Date");
        case "description":
            return validateNotEmpty(value, "Description");
        case "image":
            return validateImage(value);
        default:
            return "";
    }
}

function validatePhotoPage(fieldName, value) {
    if (fieldName === "image") {
        return validateImage(value);
    }
    return "";
}

function validateHotelPage(fieldName, value) {
    switch (fieldName) {
        case "name":
            return validateNotEmpty(value, "Hotel Name");
        case "phone":
            return validatePhoneNumber(value);
        case "blockCode":
            return validateHotelBlockCode(value);
        case "roomRate":
            return validateHotelRoomRate(value);
        default:
            return validateCommonFields(fieldName, value);
    }
}

function validateHotelBlockCode(value) {
    if (!value) return '';
    if (value.length > 15) return weddingWebsiteCustomValidation.hotelValidationMessages.blockCodeTooLong;
    if (!/^[a-zA-Z0-9]+$/.test(value)) return weddingWebsiteCustomValidation.hotelValidationMessages.blockCodeNotAlphanumeric;
    return '';
}

function validateHotelRoomRate(value) {
    if (!value) return '';
    if (value.length > 6) return weddingWebsiteCustomValidation.hotelValidationMessages.roomRateTooLong;
    if (!/^\d+$/.test(value)) return weddingWebsiteCustomValidation.hotelValidationMessages.roomRateNotNumeric;
    return '';
}

function validateQnAPage(fieldName, value) {
    switch (fieldName) {
        case "question":
            return validateNotEmpty(value, "Question text");
        case "answer":
            return validateNotEmpty(value, "Answer text");
        default:
            return "";
    }
}

function validatePersonPage(fieldName, value) {
  switch (fieldName) {
    case "name": {
      const emptyNameError = validateNotEmpty(value, "Name text");
      if (emptyNameError) return emptyNameError;

      if (!/^[a-zA-Z\s]+$/.test(value)) {
        return weddingWebsiteCustomValidation.personValidationMessages.nameAlphabetsOnly;
      }

      if (value.length < 3) {
        return weddingWebsiteCustomValidation.personValidationMessages.nameMinLength;
      }

      return value.length > 120 ? `Name ${weddingWebsiteCustomValidation.charactorLength}` : "";
    }
    case "role": {
      const emptyRoleError = validateNotEmpty(value, "Role text");
      if (emptyRoleError) return emptyRoleError;

      if (!/^[a-zA-Z\s]+$/.test(value)) {
        return weddingWebsiteCustomValidation.personValidationMessages.roleAlphabetsOnly;
      }

      if (value.length < 3) {
        return weddingWebsiteCustomValidation.personValidationMessages.roleMinLength;
      }

      return value.length > 120 ? `Role ${weddingWebsiteCustomValidation.charactorLength}` : "";
    }
    case "image":
        if (typeof value === 'string' && value.startsWith('data:image/')) {
            return validateBase64Image(value);
        }
        return validateImage(value);
    default: {
      return "";
    }
  }
}

function validateCommonFields(fieldName, value) {
  switch (fieldName) {
    case "title":
    case "name": {
      const emptyError = validateNotEmpty(value, fieldName === "title" ? "Title" : "Name");
      if (emptyError) return emptyError;

      const displayFieldName = fieldName === "title" ? "Title" : "Name"
      return value?.length > 120 ? `${displayFieldName} ${weddingWebsiteCustomValidation.charactorLength}` : "";
    }
    case "image":
      return validateBase64Image(value);
    case "address": {
      const emptyAddressError = validateNotEmpty(value, "Address");
      if (emptyAddressError) return emptyAddressError;
      return value.length > 120 ? `Address ${weddingWebsiteCustomValidation.charactorLength}` : "";
    }
    case "email":
      return validateEmail(value);
    case "website":
      return validateUrl(value);
    case "phone":
      return validatePhoneNumber(value);
    default:
      return "";
  }
}

function validateTransportPage(fieldName, value) {
  return validateCommonFields(fieldName, value);
}

function validateActivityPage(fieldName, value) {
  return validateCommonFields(fieldName, value);
}

function validateEmail(value) {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const emailValidation = !emailPattern.test(value) ? weddingWebsiteCustomValidation.emailValidation : ''
    return !value ? `Email ${weddingWebsiteCustomValidation.textValueNotExist}` : emailValidation;
}

function validatePhoneNumber(value) {
    if (!value) {
      return `Phone number ${weddingWebsiteCustomValidation.textValueNotExist}`;
    }

    // Convert to string if it's a number
    const phoneStr = String(value);

    // Check if it contains only digits and is not in exponential notation
    if (!/^\d+$/.test(phoneStr) || /e/i.test(phoneStr)) {
      return weddingWebsiteCustomValidation.phoneNumberValidation;
    }

    if (phoneStr.length < 10) {
      return weddingWebsiteCustomValidation.phoneNumberValidation;
    }

    return '';
  }
function validateLivestreamPage(fieldName, value) {
    switch (fieldName) {
        case "title": {
            return validateNotEmpty(value, "Title");
        }
        case "buttonText": {
            if (!value) return 'Button text '+weddingWebsiteCustomValidation.textValueNotExist;

            const length = value.trim().length;
            if (length === 0) return weddingWebsiteCustomValidation.liveSectionValidateMessages.btnMinLength;
            if (length > 30) return weddingWebsiteCustomValidation.liveSectionValidateMessages.btnMaxLength;

            return "";
          }
        case "livestreamLink": {
            const emptyLinkError = validateNotEmpty(value, "Live Stream Link");
            if (emptyLinkError) {
                return emptyLinkError;
            }
            if (value) {
                return validateUrl(value);
            }
            return "";
        }
        default: {
            return "";
        }
    }
}

function validateUrl(value) {
    const urlPattern = /^(https?:\/\/)?(www\.)?([a-z0-9-]+\.)+[a-z]{2,6}(\/[^\s]*)?$/i;
    return urlPattern.test(value) ? "" : "Please enter a valid URL";
}

function validateTextBlockPage(fieldName, value) {
    switch (fieldName) {
        case "titleText": {
            const emptyTitleError = validateNotEmpty(value, "Title text");
            if (emptyTitleError) {
                return emptyTitleError;
            }
            if (value) {
                return value.length > 120 ? `Title ${weddingWebsiteCustomValidation.charactorLength}` : "";
            }
            return "";
        }
        case "bodyText": {
            return validateNotEmpty(value, "Body text");
        }
        default: {
            return "";
        }
    }
}

function validateBasicInfoPage(fieldName, value) {
    switch (fieldName) {
        case "partnerName":
            return validateNotEmpty('', "Partner first name and last name", value);
        case "bnGName":
            return validateNotEmpty('', "First name and last name", value);
        case "partnerFirstName":
            return validateNotEmpty(value, "Partner first name");
        case "partnerLastName":
            return validateNotEmpty(value, "Partner last name");
        case "firstName":
            return validateNotEmpty(value, 'first name');
        case "lastName":
            return validateNotEmpty(value, 'last name');
        case "weddingDate":
            return value ? "" : "Wedding date is required";
        case "weddingLocation":
            return validateNotEmpty(value, "Wedding location");
        case "announcement":
            return ""; // Announcement can be empty
        default:
            return "";
    }
}

function validateHomeCoverTextPage(fieldName, value) {
    switch (fieldName) {
        case "coverTitle":
            return validateNotEmpty(value, "Cover title");
        case "coverText":
            return validateNotEmpty(value, "Cover text");
        default:
            return "";
    }
}

function validateEventInformationPage(fieldName, value) {
        if (!FeatureFlags.showEventInformationInEditor) {
            return "";
        }

        switch (fieldName) {
            case "eventName":
            return validateNotEmpty(value, "Event name");
        case "date":
            return validateNotEmpty(value, "Date");
        case "startTime":
            return validateNotEmpty(value, "Start time");
        case "endTime":
            return validateNotEmpty(value, "End time");
        case "venueName":
            return validateNotEmpty(value, "Venue name");
        default:
            return "";
    }
}

function validatePrivacyPage(fieldName, value) {
    if (typeof value === 'object' && value !== null) {
        if (value.requirePassword) {
            value = value.sitePassword;
        } else {
            return '';
        }
    }
    if (fieldName !== 'sitePassword') return '';

    if (!value) return weddingWebsiteCustomValidation.privacyValidateMessages.pinRequired;
    if (!/^\d+$/.test(value)) return weddingWebsiteCustomValidation.privacyValidateMessages.invalidCharactors;
    if (value.length < 4 || value.length > 6) return weddingWebsiteCustomValidation.privacyValidateMessages.invalidLength;
    if (/^(\d)\1+$/.test(value)) return weddingWebsiteCustomValidation.privacyValidateMessages.repeatedCharactors;
    if (/^(0123|1234|2345|3456|4567|5678|6789|9876|8765|7654|6543|5432|4321|3210)$/.test(value)) {
        return weddingWebsiteCustomValidation.privacyValidateMessages.sequentialDigits;
    }
    return '';
}

function validateUrlCommon(value,isRegistry) {
    if(!isRegistry)
    {


    if (value.length < 2) {
        console.log("hereee");
        return weddingWebsiteCustomValidation.websiteUrlValidationMessages.short;
    }
    if (value.length > 63) {
        return weddingWebsiteCustomValidation.websiteUrlValidationMessages.long;
    }
    }
    if (/\s/.test(value)) {
        return weddingWebsiteCustomValidation.websiteUrlValidationMessages.space;
    }
    if (/\.{2,}/.test(value)) {
        return weddingWebsiteCustomValidation.websiteUrlValidationMessages.dots;
    }
    if (value.startsWith('-') || value.endsWith('-')) {
        return weddingWebsiteCustomValidation.websiteUrlValidationMessages.dash;
    }


    return '';
}

function validateWebsiteUrlPage(fieldName, value) {
    if (fieldName !== 'customPageName') return '';
    if (/[!@#$%^&*(),.?":{}|<>]/.test(value)) {
        return weddingWebsiteCustomValidation.websiteUrlValidationMessages.specialCharactors;
    }
    return validateUrlCommon(value,false);
}

function validateRegistryPage(fieldName, value) {
    if (fieldName !== 'link') return ''
    if (!value) return weddingWebsiteCustomValidation.registryValidationMessages.invalidUrl
    const urlCommonError = validateUrlCommon(value, true)
    if (urlCommonError) return urlCommonError

    // Add URL format validation
    const urlPattern = /^(https?:\/\/)?(www\.)?([a-z0-9-]+\.)+[a-z]{2,}(\/\S*)?$/i
    if (!urlPattern.test(value)) {
      return weddingWebsiteCustomValidation.registryValidationMessages.invalidUrl
    }

    return ''
  }

export function validateAllFields(data, section) {
    const errors = {};
    if (section === "Privacy") {
        const error = validateField('sitePassword', data, section);
        if (error) {
            errors['sitePassword'] = error;
        }

    }
    else {
        Object.keys(data).forEach(field => {
            const error = validateField(field, data[field], section);
            if (error) {
                errors[field] = error;
            }
        });
    }


    return errors;
}
