var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"229b81634ebf5f00b4d018b05e8ff74bd77ffa2f"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

const env = process.env.ENV || process.env.NEXT_PUBLIC_ENV;

const tracesSampleRate = 1.0;

const disableFor = ["local", "dev", "qa"];

if (!disableFor.includes(env)) {
  const errorsToSkip = [
    `OTP retrieval was cancelled`,
    `OTP retrieval timed out`,
    `OTP retrieval request not handled`,
    `Error looking up record in object store by key range`
  ];

  const firestoreUrlsToSkip = [
    "https://firestore.googleapis.com/google.firestore.v1.Firestore/Listen/channel"
  ];

  Sentry.init({
    dsn: SENTRY_DSN,
    tracesSampleRate,
    environment: env,
    beforeSend(event) {
      // Skip specific errors
      for (const errorText of errorsToSkip) {
        if (event.exception?.values?.[0]?.value?.includes(errorText)) {
          return null;
        }
      }

      // Skip Firestore logs
      if (event.request?.url) {
        for (const url of firestoreUrlsToSkip) {
          if (event.request.url.includes(url)) {
            return null;
          }
        }
      }

      return event;
    },
    beforeSendTransaction(transaction) {
      const transactionName = transaction.transaction || "";
      if (transactionName.includes("unimportant")) {
        return null;
      }
      return transaction;
    },
  });
}