import dayjs from "dayjs";
import {
  collectionGroup,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";

import {
  getDocumentById,
  getDocumentsByCollection,
  getDocumentsByKeyAndValue,
} from "..";
import { COLLECTIONS } from "../../config/constants";
import { db } from "../../config/firebase/firebase";
import { secureStorage } from "../../lib/secureLocalStore";
import { getWeddingDate } from "../../lib/weddingWebsite";
import { validateField } from "../../lib/weddingWebsiteValidations";
import { post } from "../../req";
import { PageIds, PreviewTemplateKey, TemplateIds, WbSections,WeddingWebsiteDateFormat,defaultCoverImages } from "./constants";
import { processImage } from "./UploadImageToBucket";


const apiUrl = process.env.NEXT_PUBLIC_API_URL;

export const RefreshTypes = {
  REFRESH: "refresh",
  PAGE_NAV: "page-nav",
  PREVIEW: "preview",
  COLOR_CHANGED: "color-changed",
};

export function refreshIframe({
  type = RefreshTypes.REFRESH,
  data = {},
  targetOrigin = "*",
} = {}) {
  const iframe = document.getElementById("template-preview");
  if (iframe) {
    iframe.contentWindow.postMessage({ type, data }, targetOrigin);
  }
}

export async function saveSelectedTemplateDetails(data) {
  try {
    const mainEventId = JSON.parse(
      localStorage.getItem("userData")
    )?.MainEventId;

    const weddingWebsiteRef = doc(
      db,
      COLLECTIONS.MainEvents,
      mainEventId,
      COLLECTIONS.WeddingWebsite,
      "unpublished"
    );

    await setDoc(weddingWebsiteRef, data, { merge: true });

    refreshIframe();
    return { success: true };
  } catch (error) {
    console.error("Error storing selected template and color:", error);
    return { success: false, error };
  }
}

export async function getWeddingWebsiteInfo() {
  try {
    const { MainEventId: mainEventId } = JSON.parse(
      localStorage.getItem("userData")
    );

    const weddingWebsiteRef = doc(
      db,
      COLLECTIONS.MainEvents,
      mainEventId,
      COLLECTIONS.WeddingWebsite,
      "unpublished"
    );
    const docSnap = await getDoc(weddingWebsiteRef);

    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      return null;
    }
  } catch (error) {
    console.error("Error fetching wedding website info:", error);
    return null;
  }
}

const templatePreviewImagePath = "/images/wedding-websites/template-preview";
export const weddingWebsites = [
  {
    id: TemplateIds.ROSE_BUD,
    name: "Rose Bud",
    defaultCoverImage: "",
    editorPreviewImageWbp: `${templatePreviewImagePath}/rose-bud.webp`,
    url: `${TemplateIds.ROSE_BUD}/${PageIds.HOME}`,
    description:
      "Rose Bud is a beautiful and elegant template that is perfect for a romantic wedding.",
    colors: [
      { colorCode: "#33434A", colorClass: "rose-bud-theme1" },
      { colorCode: "#570F00", colorClass: "rose-bud-theme2" },
      { colorCode: "#510038", colorClass: "rose-bud-theme3" },
      { colorCode: "#020030", colorClass: "rose-bud-theme4" },
    ],
    singlePageTemplate: false,
    visible: false,
  },
  {
    id: TemplateIds.TOLEDO,
    name: "Toledo",
    defaultCoverImage: "",
    editorPreviewImageWbp: `${templatePreviewImagePath}/toledo.webp`,
    url: `${TemplateIds.TOLEDO}/${PageIds.HOME}`,
    description:
      "Toledo is a beautiful and elegant template that is perfect for a romantic wedding.",
    colors: [
      { colorCode: "#663853", colorClass: "toledo-theme1" },
      { colorCode: "#28003A", colorClass: "toledo-theme2" },
      { colorCode: "#003B58", colorClass: "toledo-theme3" },
      { colorCode: "#520063", colorClass: "toledo-theme4" },
    ],
    singlePageTemplate: false,
    visible: false,
  },
  {
    id: TemplateIds.ARYA,
    name: "Arya",
    defaultCoverImage:defaultCoverImages.ARYA,
    editorPreviewImageWbp: `${templatePreviewImagePath}/${TemplateIds.ARYA}.webp`,
    url: `${TemplateIds.ARYA}/${PageIds.HOME}`,
    description:
      "Cozy, timeless, and totally inviting—Arya’s earthy palette and refined design create the perfect space for sharing your love story with depth and natural charm.",
    colors: [
      { colorCode: "#708C66", colorClass: "arya-theme1" },
      { colorCode: "#CF734F", colorClass: "arya-theme2" },
      { colorCode: "#018B9D", colorClass: "arya-theme3" },
      { colorCode: "#B8963B", colorClass: "arya-theme4" },
      { colorCode: "#663399", colorClass: "arya-theme5" },
      { colorCode: "#B83A4F", colorClass: "arya-theme6" },
    ],
    singlePageTemplate: true,
    visible: true,
  },
  {
    id: TemplateIds.SAIRA,
    name: "Saira",
    defaultCoverImage:defaultCoverImages.SAIRA,
    editorPreviewImageWbp: `${templatePreviewImagePath}/${TemplateIds.SAIRA}.webp`,
    url: `${TemplateIds.SAIRA}/${PageIds.HOME}`,
    description:
      "If you’re looking for something calm and cool, Saira’s soothing tones and tranquil style offer the perfect backdrop to share your love story—relaxed, inviting, and totally carefree.",
    colors: [
      { colorCode: "#8FABBB", colorClass: "saira-theme1" },
      { colorCode: "#FB6376", colorClass: "saira-theme2" },
      { colorCode: "#E5904D", colorClass: "saira-theme3" },
      { colorCode: "#00A896", colorClass: "saira-theme4" },
      { colorCode: "#71816D", colorClass: "saira-theme5" },
      { colorCode: "#00B4D8", colorClass: "saira-theme6" },
    ],
    singlePageTemplate: true,
    visible: true,
  },
  {
    id: TemplateIds.KALYANI,
    name: "Kalyani",
    defaultCoverImage:defaultCoverImages.KALYANI,
    editorPreviewImageWbp: `${templatePreviewImagePath}/${TemplateIds.KALYANI}.webp`,
    url: `${TemplateIds.KALYANI}/${PageIds.HOME}`,
    description:
      "Designed with modern simplicity in mind, Kalyani’s chic layout and elegant details create a sophisticated space for you to share your wedding story with effortless style.",
    colors: [
      { colorCode: "#DDB24E", colorClass: "kalyani-theme1" },
      { colorCode: "#F19C79", colorClass: "kalyani-theme2" },
      { colorCode: "#C1D662", colorClass: "kalyani-theme3" },
      { colorCode: "#06D6A0", colorClass: "kalyani-theme4" },
    ],
    singlePageTemplate: true,
    visible: true,
  },
  {
    id: TemplateIds.SANDHYA,
    name: "Sandhya",
    defaultCoverImage: defaultCoverImages.SANDHYA,
    editorPreviewImageWbp: `${templatePreviewImagePath}/${TemplateIds.SANDHYA}.webp`,
    url: `${TemplateIds.SANDHYA}/${PageIds.HOME}`,
    description:
      "Classic meets contemporary with Sandhya, where rich tones and a sleek design come together to share your wedding details in a space that feels as timeless as your love.",
    colors: [
      { colorCode: "#EDA033", colorClass: "sandhya-theme1" },
      { colorCode: "#D82A2A", colorClass: "sandhya-theme2" },
      { colorCode: "#00A36C", colorClass: "sandhya-theme3" },
      { colorCode: "#0778C2", colorClass: "sandhya-theme4" },
      { colorCode: "#DB2363", colorClass: "sandhya-theme5" },
    ],
    singlePageTemplate: true,
    visible: true,
  },
  {
    id: TemplateIds.JIYA,
    name: "Jiya",
    defaultCoverImage: defaultCoverImages.JIYA,
    editorPreviewImageWbp: `${templatePreviewImagePath}/${TemplateIds.JIYA}.webp`,
    url: `${TemplateIds.JIYA}/${PageIds.HOME}`,
    description:
      "Jiya’s regal design is a perfect blend of elegance and warmth, offering a luxurious yet cozy space for you to share your wedding journey with a touch of class and heart.",
    colors: [
      { colorCode: "#550253", colorClass: "jiya-theme1" },
      { colorCode: "#C16E00", colorClass: "jiya-theme2" },
      { colorCode: "#8517B2", colorClass: "jiya-theme3" },
      { colorCode: "#177887", colorClass: "jiya-theme4" },
      { colorCode: "#BE132D", colorClass: "jiya-theme5" },
    ],
    singlePageTemplate: true,
    visible: true,
  },
  {
    id: TemplateIds.ESHA,
    name: "Esha",
    defaultCoverImage:defaultCoverImages.ESHA,
    editorPreviewImageWbp: `${templatePreviewImagePath}/${TemplateIds.ESHA}.webp`,
    url: `${TemplateIds.ESHA}/${PageIds.HOME}`,
    description:
      "Esha’s vibrant palette and chic design create the ideal space for couples who want their wedding details to pop, offering a fun, fresh vibe that’s as stylish as it is bold.",
    colors: [
      { colorCode: "#C59880", colorClass: "esha-theme1" },
      { colorCode: "#CB4F6F", colorClass: "esha-theme2" },
      { colorCode: "#5D60BD", colorClass: "esha-theme3" },
      { colorCode: "#00A896", colorClass: "esha-theme4" },
      { colorCode: "#D17755", colorClass: "esha-theme5" },
      { colorCode: "#B242B1", colorClass: "esha-theme6" },
    ],
    singlePageTemplate: true,
    visible: true,
  },
];

async function refreshStaticPages({
  customPageName = "",
  pageIds = [],
  isSinglePageTemplate = false,
}) {
  const queryParams = new URLSearchParams({
    customPageName,
    pageIds,
    isSinglePageTemplate,
  });

  await post(
    `${
      process.env.NEXT_PUBLIC_BASE_URL
    }/api/revalidate?${queryParams.toString()}`
  );
}

export async function publishWeddingWebsite() {
  try {
    const mainEventId = JSON.parse(
      localStorage.getItem("userData")
    )?.MainEventId;

    const unpublishedRef = doc(
      db,
      COLLECTIONS.MainEvents,
      mainEventId,
      COLLECTIONS.WeddingWebsite,
      "unpublished"
    );

    const unpublishedDoc = await getDoc(unpublishedRef);

    if (unpublishedDoc.exists()) {
      const publishedData = unpublishedDoc.data();
      const { customPageName } = publishedData;

      const lastPublishedDate = new Date();

      delete publishedData.createdAt;
      publishedData.updatedAt = lastPublishedDate;
      publishedData.mainEventId = mainEventId;

      // Delete all other published docs with the same mainEventId
      const publishedDocs = await getDocumentsByKeyAndValue(
        COLLECTIONS.PublishedWeddingWebsites,
        "mainEventId",
        mainEventId
      );

      const deletePromises = publishedDocs
        .filter(i => i.id !== customPageName)
        .map(document =>
          deleteDoc(doc(db, COLLECTIONS.PublishedWeddingWebsites, document.id))
        );
      await Promise.all(deletePromises);

      // Publish the new doc or update the existing one
      await Promise.all([
        setDoc(
          doc(db, COLLECTIONS.PublishedWeddingWebsites, customPageName),
          publishedData,
          { merge: true }
        ),
        updateDoc(unpublishedRef, {
          lastPublishedDate,
          updatedAt: lastPublishedDate,
        }),
      ]);

      // create new static pages
      const pageIds = publishedData.isSinglePageTemplate
        ? [PageIds.HOME]
        : publishedData.pages.filter(p => p.visible).map(p => p.id);

      await refreshStaticPages({
        customPageName,
        pageIds,
        isSinglePageTemplate: publishedData.isSinglePageTemplate,
      });

      return true;
    }
  } catch (error) {
    console.error("Error publishing wedding website:", error);
    return false;
  }
}

export async function getPublishedWeddingWebsite(customPageName) {
  let data = await getDocumentById(
    COLLECTIONS.PublishedWeddingWebsites,
    customPageName
  );

  if (!data.id) {
    return null;
  }

  const Types = {
    string: "string",
    object: "object",
    array: "array",
    boolean: "boolean",
  };

  // below object should have all the keys used in
  // getStaticProps of pages/[weddingWebsiteCustomPageName]/[pageId].js
  const keys = [
    { key: "color", type: Types.string },
    { key: "customPageName", type: Types.string },
    { key: "home", type: Types.object },
    { key: "pages", type: Types.array },
    { key: "sections", type: Types.object },
    { key: "sectionState", type: Types.object },
    { key: "templateId", type: Types.string },
    { key: "sitePassword", type: Types.string },
    { key: "requirePassword", type: Types.boolean },
    { key: "domain", type: Types.object },
    { key: "registries", type: Types.array },
    { key: "isSinglePageTemplate", type: Types.boolean },
    { key: "sectionSeperator", type: Types.object },
    { key: "events", type: Types.array },
  ];

  keys.forEach(({ key, type }) => {
    if (!(key in data) || data[key] === undefined) {
      switch (type) {
        case Types.string:
          data[key] = "";
          break;
        case Types.object:
          data[key] = {};
          break;
        case Types.array:
          data[key] = [];
          break;
        case Types.boolean:
          data[key] = null;
          break;
        default:
          data[key] = null;
      }
    }
  });

  function formatDates(obj) {
    function processValue(value) {
      if (value && typeof value.toDate === "function") {
        return getWeddingDate(value);
      }
      if (Array.isArray(value)) {
        return value.map(item => processValue(item));
      }
      if (typeof value === "object" && value !== null) {
        return formatDates(value);
      }
      return value;
    }

    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [key, processValue(value)])
    );
  }

  data = formatDates(data);

  return data;
}

export function getWeddingWebsiteCustomPaths(
  customPageName,
  pageIds,
  isSinglePageTemplate
) {
  function pageIdsToPath() {}
  const constantPageIds = [
    PageIds.HOME,
    PageIds.OUR_STORY,
    PageIds.PHOTOS,
    PageIds.WEDDING_PARTY,
    // PageIds.RSVP,
    PageIds.QA,
    PageIds.REGISTRY,
    PageIds.THINGS_TO_DO,
    PageIds.TRAVEL,
  ];

  let allPageIds = [];
  if (isSinglePageTemplate) {
    allPageIds = [PageIds.HOME];
  } else {
    allPageIds = [...new Set([...constantPageIds, ...pageIds])].filter(
      pageId => pageId !== PageIds.PRIVACY_URL && !pageId.startsWith("custom")
    );
  }

  return allPageIds.map(pageId => `/${customPageName}/${pageId}`);
}

export async function getAllWeddingWebsiteCustomPageNames() {
  const publishedSites = await getDocumentsByCollection(
    COLLECTIONS.PublishedWeddingWebsites
  );

  return publishedSites
    .map(siteDoc =>
      getWeddingWebsiteCustomPaths(
        siteDoc.id,
        siteDoc.pages.filter(p => p.visible).map(p => p.id)
      )
    )
    .flat();
}

export async function customPageAvailable(customPageName) {
  const mainEventId = JSON.parse(localStorage.getItem("userData"))?.MainEventId;

  const ref = query(
    collectionGroup(db, COLLECTIONS.WeddingWebsite),
    where("customPageName", "==", customPageName),
    where("mainEventId", "!=", mainEventId)
  );

  const querySnapshot = await getDocs(ref);

  return querySnapshot.empty;
}

export function copyWebsiteUrl(customPageName, domain) {
  let url = "";
  const domainPurchased = domain?.purchased && domain?.domain;
  if (domainPurchased) {
    url = `${domainPurchased}`;
  } else {
    url = `${window.location.origin}/${customPageName}/home`;
  }
  navigator.clipboard.writeText(url);
  return url;
}

export async function processSections(sections, sectionState, selectedPage) {
  const sectionPromises = sections.map(async (type, index) => {
    const section = { ...sectionState[index] };
    const uniqueKey = `${selectedPage}_${type}_${index}`;
    section.key = uniqueKey;

    switch (type) {
      case WbSections.Activity.id:
      case WbSections.Hotel.id:
      case WbSections.Story.id:
      case WbSections.Transport.id:
      case WbSections.Person.id:
        section.image = await processImage(section.image);
        if (section.secondImage) {
          section.secondImage = await processImage(section.secondImage);
        }
        break;

      case WbSections.Photo.id:
        section.images = await Promise.all(
          section.images.map(async (image, imgIndex) => ({
            ...image,
            key: `${uniqueKey}_image_${imgIndex}`,
            src: await processImage(image.src),
          }))
        );
        break;

      case WbSections.PhotoTimeline.id:
        section.photoMemoryItems = await Promise.all(
          section.photoMemoryItems.map(async (item, itemIndex) => ({
            ...item,
            key: `${uniqueKey}_item_${itemIndex}`,
            image: await processImage(item.image),
          }))
        );
        break;

      case WbSections.GIf.id:
        section.gif = await processImage(section.gif);
        break;
    }

    return section;
  });

  return await Promise.all(sectionPromises);
}

export function validateAllCustomFields(sections, sectionState, selectedPage) {
  const validateSections = sections[selectedPage] || [];
  const sectionStateForPage = sectionState[selectedPage] || [];
  // If validateSections is empty, return isValid as true
  if (validateSections.length === 0) {
    return { errors: {}, isValid: true };
  }

  const errors = validateSections.map((sectionType, index) => {
    const sectionValidationVars =
      WbSections[sectionType]?.validationVariables || [];

    const sectionErrors = sectionValidationVars.map(value => {
      const sectionData =
        sectionStateForPage[index]?.photoMemoryItems ||
        sectionStateForPage[index]?.images ||
        sectionStateForPage[index];
      if (Array.isArray(sectionData)) {
        if (sectionData.length === 0) {
          return [{ [value]: { itemError: "No items found" } }];
        }
        const arrayErrors = sectionData.map(item => {
          const itemError = validateField(
            value,
            item[value] || item["src"] || "",
            sectionType
          );

          return itemError ? { [value]: { itemError } } : null;
        });
        return arrayErrors.filter(Boolean);
      } else {
        // If sectionData is not an array, validate it directly
        const error = validateField(
          value,
          sectionData?.[value] || "",
          sectionType
        );
        return error ? { [value]: error } : null;
      }
    });

    const filteredSectionErrors = sectionErrors.flat().filter(Boolean);

    return filteredSectionErrors.length > 0
      ? { [index]: Object.assign({}, ...filteredSectionErrors) }
      : null;
  });

  const filteredErrors = errors.filter(Boolean);

  return {
    errors: Object.assign({}, ...filteredErrors),
    isValid: filteredErrors.length === 0,
  };
}

export async function checkDomainAvailability(domain) {
  try {
    const response = await post(`${apiUrl}/domain/check-availability`, {
      domain,
    });
    return response;
  } catch (error) {
    console.error("Error checking domain availability:", error);
    return {
      available: false,
      error:
        error?.message ||
        "Something went wrong while checking domain availability!",
    };
  }
}

export async function generateCheckoutUrl() {
  try {
    const mainEventId = JSON.parse(
      localStorage.getItem("userData")
    )?.MainEventId;

    const response = await post(`${apiUrl}/domain/generate-payment-url`, {
      mainEventId,
    });
    return { url: response?.body?.data?.url };
  } catch (error) {
    return {
      error: error?.message || "Something went wrong!",
    };
  }
}

export async function purchaseDomain() {
  try {
    const mainEventId = JSON.parse(
      localStorage.getItem("userData")
    )?.MainEventId;

    const mainEventInfo = await getDocumentById(
      COLLECTIONS.MainEvents,
      mainEventId
    );

    if (mainEventInfo?.domain?.purchased) {
      return { success: true, alreadyPurchased: true };
    }

    const response = await post(`${apiUrl}/domain/purchase`, {
      mainEventId,
    });

    if (response?.statusCode === 200) {
      return { success: true };
    }

    return { success: false, error: response?.body?.message };
  } catch (error) {
    console.error("Error purchasing domain:", error);
    return {
      success: false,
      error: error?.body?.message || "Something went wrong!",
    };
  }
}

export function showNavigation(pages, onTemplateDemo) {
  if (onTemplateDemo) {
    return true;
  }

  const visiblePageCount =
    pages
      ?.filter(page => ![PageIds.HOME, PageIds.PRIVACY_URL].includes(page.id))
      ?.filter(page => page.visible)?.length ?? 0;

  return visiblePageCount > 0;
}

export function openUrl(website, newTab = true) {
  if (!website) return

  const formattedWebsite =
    website.startsWith("http://") || website.startsWith("https://")
      ? website
      : `https://${website}`;

  window.open(formattedWebsite, newTab ? "_blank" : "_self");
}

export async function weddingWebsiteAvailable() {
  const mainEventId = JSON.parse(localStorage.getItem("userData"))?.MainEventId;
  const key = `${mainEventId}_wedding_website_available`;

  const available = secureStorage.getItem(key);
  if (available) {
    return true;
  }

  const weddingWebsite = await getDocumentById(
    COLLECTIONS.MainEvents,
    mainEventId,
    COLLECTIONS.WeddingWebsite,
    "unpublished"
  );
  if (weddingWebsite?.id) {
    secureStorage.setItem(key, true);
    return true;
  }

  return false;
}

export function getEventDetailsForWeddingWebsite(user) {
  const events = user?.MainEventDetails?.MyEvents?.map(e => {
    let { eventName, eventDate, fromDate, toDate, city, state = {} } = e;

    eventDate = eventDate ? eventDate.toDate() : "";
    fromDate = fromDate ? fromDate.toDate() : eventDate;
    toDate = toDate ? toDate.toDate() : "";

    let day = "";
    let date = "";
    const eventObj = dayjs(eventDate);
    if (eventObj.isValid()) {
      day = eventObj.format("dddd");
      date = eventObj.format(WeddingWebsiteDateFormat);
    }

    function getTime(dateVal) {
      let time = "";

      if (dayjs(dateVal).isValid()) {
        return dayjs(dateVal).format("hh:mm A");
      }

      return time;
    }

    const from = { day, date, time: getTime(fromDate) };
    const to = { day, date, time: getTime(toDate) };

    if (from.time === "12:00 AM" && !to.time) {
      from.time = "";
    }

    return {
      eventName,
      eventDate,
      from,
      to,
      location: city && state ? `${city}, ${state}` : "",
    };
  });

  return events;
}

export async function fetchAndSaveEventDetails(user) {
  const events = getEventDetailsForWeddingWebsite(user);
  saveSelectedTemplateDetails({ events });
}

export const FeatureFlags = {
  allowAddingNewPageForSinglePageTemplate: false,
  showEventInformationInEditor: false,
};

export function resetPreviewTemplate() {
  secureStorage.removeItem(PreviewTemplateKey);
}
