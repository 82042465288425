import dayjs from "dayjs";

import { UsDateFormat } from "../config/constants";

export function getWeddingDate(date, dateFormat = UsDateFormat) {
  if (typeof date === "string") {
    return date;
  }

  if (!date) {
    return "";
  }

  if (date.seconds && !isNaN(date.seconds)) {
    const formattedDate = dayjs.unix(date.seconds).format(dateFormat);
    return dayjs(formattedDate, dateFormat).isValid() ? formattedDate : "";
  }

  if (dayjs(date).isValid()) {
    return dayjs(date).format(dateFormat);
  }

  return ""
}
